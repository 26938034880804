import '../src/css/uikit-rtl.css';
import '../src/css/uikit-rtl.min.css';
import '../src/css/uikit.css';
import '../src/css/uikit.min.css';
import './App.css';

import React, { useState, useEffect } from "react"
import axios from 'axios';

import Logo from "../src/img/fonpaylasimi.svg"
import Image1 from "../src/img/image1.png"
import Image2 from "../src/img/image2.png"
import Image3 from "../src/img/image3.png"
import Image5 from "../src/img/image5.png"
import Image6 from "../src/img/image6.png"
import Icon1 from "../src/img/icons/fi_1077114.svg"
import Icon2 from "../src/img/icons/fi_2893811.svg"
import Icon3 from "../src/img/icons/fi_5585562.svg"
import ThankYouImg from "../src/img/icons/hand_3874305.svg"

import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

function App() {
    const url = window.location
    const params = new URLSearchParams(url.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(event.target)

        const fullName = name.trim();
        const phoneNumber = phone.trim();

        // İsim ve Soyisim ayrıştırması
        const nameArray = fullName.split(' ');
        const lastName = nameArray.pop(); // Soyad
        const firstName = nameArray.join(' '); // Geri kalan adlar

        const userData = {
            firstName: firstName,
            lastName: lastName,
            email: 'lp@fonpaylasim.co',  // Sabit email
            phoneNumber: phoneNumber,
            UtmSource: utmSource ? utmSource : ".",
            UtmMedium: utmMedium ? utmMedium : "."
        };

        try {
            const response = await axios.post('https://fonpaylasim.co/api/Form/SubmitForm', userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setIsSubmitted(true)
        } catch (error) {
            Swal.fire({
                title: "Hata",
                text: "Bir hata oluştu. Lütfen tekrar deneyin.",
                icon: "error"
            });
        }
    };
    return (
        <div>
            <div>
                <div className="uk-container uk-container-xlarge">
                    <header>
                        <nav><img src={Logo} alt="Fon Paylaşımı Logo" /></nav>
                    </header>
                </div>
                {
                    isSubmitted ?
                        <div className="uk-container uk-container-large">
                            <div className="thank-you-card">
                                <img src={ThankYouImg} alt="thankyou" width="75px" />
                                <h5 style={{ fontSize: 32, fontWeight: 500, color: "#2D2CBE" }}>Form Başarıyla Gönderilmiştir.</h5>
                                <p style={{ fontSize: 16 }}>Doldurduğunuz form başarıyla iletilmiştir. Sizi en kısa sürede bilgilendireceğiz.</p>
                                <button onClick={() => setIsSubmitted(false)}>Ana Sayfaya Dön</button>
                            </div>
                            <footer className="uk-margin-xlarge-top uk-margin-xlarge-bottom">
                                <div className="footer-designer">
                                    <div className="logo-wrapper">
                                        <img className="footer-logo" alt="" src={Logo} />
                                    </div>
                                    <div className="image-5-parent">
                                        <img className="image-5-icon" alt="" src={Image5} />
                                        <img className="image-6-icon" alt="" src={Image6} />
                                    </div>
                                </div>
                                <p className='copyright-footer' style={{ color: '#999', fontSize: '15px' }}>Copyright Fon Paylaşım</p>
                            </footer>
                        </div>
                        :
                        <div className="uk-container uk-container-large">
                            <div className="uk-grid uk-grid-medium uk-flex-center uk-flex-middle uk-margin-large-top" uk-grid>
                                <div className="uk-width-1-2@s">
                                    <h1>Ayşe Hanım’ın <span>Milyon Dolarlık</span> Başarı Hikayesi</h1>
                                    <p>Haberin detaylarını sizler için derledik.</p>
                                    <p>Ferhat Adıvar’ın Röportajı - 17.09.2024</p>
                                    <p>
                                        Yıllarca zorluklarla mücadele eden Ayşe Hanım, alım-satım işlemleri sayesinde kısa sürede
                                        milyonlarca dolar kazandı.
                                        İşte hayatını değiştiren o süreç ve sizin de benzer bir başarı elde etmenizi sağlayacak detaylar.
                                    </p>
                                    <a className="button" href="#form">Detayları öğrenin</a>
                                </div>
                                <div className="uk-width-1-2@s hero-image">
                                    <img className="img1" src={Image1} alt="" />
                                </div>
                            </div>
                            <blockquote className="uk-margin-large-top">
                                <p className="uk-text-large uk-text-italic uk-margin-remove">
                                    “Hayatım boyunca hep ezildim, görmezden gelindim. Çocuklarımın isteklerini karşılayamamak beni en çok
                                    üzen şeydi.
                                    Her gece bir sonraki günün endişesiyle uykuya dalıyordum.”
                                </p>
                                <footer className="uk-margin-small-top uk-text-right color-white">— Ayşe Hanım</footer>
                            </blockquote>
                            <h2 className="uk-margin-xlarge-top">Dönüm Noktası Alım-Satım İşlemleriyle Tanışma</h2>
                            <p>Bir gün, internette gezinirken alım-satım işlemleriyle ilgili bir platformun kayıt formuyla karşılaştı. Başta
                                tereddüt etti; çünkü finansal konularda deneyimi yoktu ve daha önce böyle bir işe girişmemişti. Ancak
                                kaydolmanın ücretsiz olduğunu görünce denemeye karar verdi.</p>
                            <blockquote className="uk-margin-large-top">
                                <p className="uk-text-large uk-text-italic uk-margin-remove">
                                    “Kayıt oldum ve kısa süre içinde bir yatırım uzmanı beni aradı. Bana her şeyi adım adım anlattı ve güven
                                    verdi. İlk başta korkularım vardı ama kaybedecek bir şeyim olmadığını düşündüm. Küçük bir yatırımla
                                    başladım ve ilk kazancımı görünce gözlerime inanamadım.”
                                </p>
                                <footer className="uk-margin-small-top uk-text-right color-white">— Ayşe Hanım</footer>
                            </blockquote>
                            <div className="uk-grid uk-grid-medium uk-flex-center uk-flex-middle uk-margin-xlarge-top" uk-grid>
                                <div className="uk-width-1-2@s">
                                    <h2>Geleceğinizi Değiştirmek İçin İlk Adımı Atın</h2>
                                    <p>Hayallerinizi ertelemeyin. Ayşe Hanım gibi siz de finansal özgürlüğe kavuşabilir, sevdiklerinize daha
                                        iyi bir gelecek sunabilirsiniz.</p>
                                    <h3>Ayşe Hanım Gibi Kısa Sürede Kazanmaya Başlayın</h3>
                                    <p>Adım 1: Kayıt formunu doldurun.</p>
                                    <p>Adım 2: Kazanmaya nasıl başlayacağınıza dair bilgi edinin.</p>
                                    <p>Adım 3: Ücretsiz olarak temin edebileceğiniz alım-satım kılavuzunu izleyin.</p>
                                    <p>Adım 4: Uzmanların tavsiyelerini dinleyip yatırım yapmak için doğru zamanı belirleyin.</p>
                                    <p>Adım 5: Büyük kazançlar elde edin!</p>
                                </div>
                                <div className="uk-width-1-2@s hero-image">
                                    <div className="form" id="form">
                                        <form id="registrationForm" onSubmit={handleSubmit}>
                                            <div className="last-users">
                                                <div className="first">
                                                    <img alt="Yıldız" src={Icon2} /> <b>SON 8 KİŞİ!</b>
                                                </div>
                                                <div className="last">SINIRLI KONTENJAN</div>
                                            </div>
                                            <div className="f-group">
                                                <p className="form-info">11.992 Kişi Kayıt Oldu</p>
                                                <b className="title">Siz de Ücretsiz Bilgi Edinin ve Kazanmaya Başlayın</b>
                                                <div className="f-container">
                                                    <div className="uk-inline">
                                                        <span className="custom-icon">
                                                            <img src={Icon1} alt="Adınız soyadınız" />
                                                        </span>
                                                        <input onChange={(e) => setName(e.target.value)} className="uk-input" type="text" name="fullName" id='fullName' placeholder="Adınız soyadınız" required />
                                                    </div>
                                                    <div className="uk-inline">
                                                        <span className="custom-icon">
                                                            <img src={Icon3} alt="Telefon Numaranız" />
                                                        </span>
                                                        <InputMask
                                                            mask="(599) 999 9999"
                                                            maskChar="_"
                                                            value={phone}
                                                            onChange={(e) => {
                                                                setPhone(e.target.value.replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("_", ""))
                                                            }}
                                                        >
                                                            {() => (
                                                                <input className="uk-input" type="tel" name="phoneNumber" id='phoneNumber' placeholder="Telefon Numaranız" required />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-button">
                                                <button type="submit">Hemen Kaydol</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-container box uk-margin-xlarge-top">
                                <p className="info uk-text-center">Herkes kolayca ve hızlıca öğrenebilir. Endişe edilecek, korkulacak bir durum
                                    yok.</p>
                                <h2 className="uk-text-center info">Siz de Ayşe Hanım Gibi Başarıya Ulaşabilirsiniz</h2>
                                <p className="uk-text-center">Ayşe Hanım’ın hikâyesi, doğru adımları atan herkesin başarıya ulaşabileceğini
                                    gösteriyor. İşte Ayşe Hanım gibi milyonlarca dolarlık kazanca uzanan 5 basit adım:</p>
                                <ul>
                                    <li className="list-item">
                                        <svg width={64} height={65} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.487793" width={64} height={64} rx={4} fill="#3832DB" />
                                            <path d="M25.9287 23.2177V16.2478H38.0712V48.2478H30.2684V23.2177H25.9287Z" fill="white" />
                                        </svg>
                                        <p><b>Kayıt Formunu Doldurun:</b> Ücretsiz kayıt olarak ilk adımı atın.</p>
                                    </li>
                                    <li className="list-item">
                                        <svg width={64} height={65} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.487793" width={64} height={64} rx={4} fill="#3832DB" />
                                            <path d="M21.1304 42.7981C22.1159 42.0155 22.5652 41.6532 22.4782 41.7111C25.3188 39.3633 27.5507 37.4358 29.1739 35.9285C30.8261 34.4213 32.2174 32.8416 33.3478 31.1894C34.4782 29.5372 35.0435 27.9285 35.0435 26.3633C35.0435 25.1749 34.7681 24.2474 34.2174 23.5807C33.6666 22.914 32.8406 22.5807 31.7391 22.5807C30.6377 22.5807 29.7681 23.001 29.1304 23.8416C28.5217 24.6532 28.2174 25.8126 28.2174 27.3198H21.0435C21.1014 24.8561 21.6232 22.7981 22.6087 21.1459C23.6232 19.4937 24.942 18.2763 26.5652 17.4937C28.2174 16.7111 30.0435 16.3198 32.0435 16.3198C35.4927 16.3198 38.0869 17.2039 39.8261 18.972C41.5942 20.7401 42.4782 23.0445 42.4782 25.885C42.4782 28.9865 41.4203 31.8705 39.3043 34.5372C37.1884 37.1749 34.4927 39.7546 31.2174 42.2763H42.9565V48.3198H21.1304V42.7981Z" fill="white" />
                                        </svg>
                                        <p><b>Bilgi Edinin:</b> Uzmanlarımızın size özel sunduğu bilgileri öğrenin.</p>
                                    </li>
                                    <li className="list-item">
                                        <svg width={64} height={65} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.487793" width={64} height={64} rx={4} fill="#3832DB" />
                                            <path d="M21.588 26.0006C21.7014 22.9691 22.693 20.6318 24.5628 18.9886C26.4327 17.3454 28.9683 16.5238 32.1697 16.5238C34.2945 16.5238 36.1077 16.8921 37.6093 17.6287C39.1392 18.3653 40.2866 19.3711 41.0515 20.646C41.8448 21.9209 42.2414 23.3516 42.2414 24.9381C42.2414 26.808 41.774 28.3379 40.839 29.5278C39.9041 30.6894 38.8134 31.4826 37.5668 31.9076V32.0776C39.1817 32.6159 40.4566 33.5083 41.3915 34.7549C42.3264 36.0014 42.7939 37.6022 42.7939 39.557C42.7939 41.3135 42.3831 42.8717 41.5615 44.2316C40.7682 45.5632 39.5925 46.6115 38.0343 47.3764C36.5044 48.1413 34.677 48.5238 32.5522 48.5238C29.1524 48.5238 26.4327 47.688 24.3928 46.0165C22.3813 44.345 21.3189 41.8235 21.2056 38.4521H28.26C28.2883 39.6987 28.6425 40.6903 29.3224 41.4269C30.0024 42.1351 30.994 42.4893 32.2972 42.4893C33.4021 42.4893 34.252 42.1776 34.847 41.5543C35.4703 40.9027 35.7819 40.0528 35.7819 39.0045C35.7819 37.6447 35.3428 36.6672 34.4645 36.0723C33.6146 35.449 32.2405 35.1374 30.3424 35.1374H28.9825V29.2303H30.3424C31.7872 29.2303 32.9488 28.9895 33.8271 28.5079C34.7337 27.9979 35.187 27.1055 35.187 25.8306C35.187 24.8107 34.9037 24.0174 34.337 23.4508C33.7704 22.8841 32.9913 22.6008 31.9997 22.6008C30.9231 22.6008 30.1157 22.9266 29.5774 23.5783C29.0675 24.2299 28.77 25.0373 28.685 26.0006H21.588Z" fill="white" />
                                        </svg>
                                        <p><b>Eğitim Materyallerini İnceleyin:</b> Ücretsiz olarak sunulan alım-satım kılavuzlarını
                                            kullanın.</p>
                                    </li>
                                    <li className="list-item">
                                        <svg width={64} height={65} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.487793" width={64} height={64} rx={4} fill="#3832DB" />
                                            <path d="M18.9111 42.35V36.2167L32.9556 16.1278H41.4445V35.7723H45.0889V42.35H41.4445V48.1278H33.8445V42.35H18.9111ZM34.3778 24.4834L26.9111 35.7723H34.3778V24.4834Z" fill="white" />
                                        </svg>
                                        <p><b>Uzman Tavsiyelerini Dinleyin:</b> Doğru zamanda yatırım yapmak için uzman önerilerini takip
                                            edin.</p>
                                    </li>
                                    <li className="list-item">
                                        <svg width={64} height={65} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.487793" width={64} height={64} rx={4} fill="#3832DB" />
                                            <path d="M41.4775 22.8261H27.8195V28.8672C28.4032 28.2251 29.2203 27.6998 30.2709 27.2912C31.3216 26.8827 32.4597 26.6784 33.6854 26.6784C35.8742 26.6784 37.6836 27.1745 39.1136 28.1667C40.5728 29.159 41.638 30.4431 42.3093 32.019C42.9805 33.5949 43.3161 35.3022 43.3161 37.1407C43.3161 40.5553 42.353 43.2693 40.4269 45.283C38.5008 47.2675 35.7867 48.2598 32.2846 48.2598C29.9499 48.2598 27.9217 47.8658 26.1998 47.0778C24.478 46.2607 23.1501 45.1371 22.2162 43.7071C21.2823 42.2771 20.7716 40.6282 20.6841 38.7605H27.9946C28.1697 39.6651 28.5929 40.4239 29.2641 41.0368C29.9353 41.6205 30.8546 41.9123 32.022 41.9123C33.3936 41.9123 34.415 41.4745 35.0863 40.599C35.7575 39.7235 36.0931 38.5562 36.0931 37.097C36.0931 35.667 35.7429 34.5726 35.0425 33.8138C34.3421 33.055 33.3207 32.6756 31.9782 32.6756C30.986 32.6756 30.1688 32.9237 29.5268 33.4198C28.8847 33.8868 28.4616 34.5142 28.2573 35.3022H21.0343V16.2598H41.4775V22.8261Z" fill="white" />
                                        </svg>
                                        <p><b>Kazanç Elde Edin:</b> Finansal özgürlüğe giden yolda büyük kazançlar elde edin.</p>
                                    </li>
                                </ul>
                                <p>Sınırlı Kontenjan: <b>Son 8 Kişi!</b></p>
                                <p>Şu an <b>12.000 kişi kayıtlı</b> ve kontenjanımız sınırlı. Son 8 kişiden biri olmak için acele edin!</p>
                                <a className="box-button uk-margin-medium-top" href="#form">HEMEN KAZANMAYA BAŞLA!</a>
                            </div>
                            <div className="uk-grid uk-grid-medium uk-flex-center uk-flex-middle uk-margin-xlarge-top" uk-grid>
                                <div className="uk-width-1-2@s">
                                    <h2>Engelleri Aşmak ve Başarıya Ulaşmak</h2>
                                    <p>Ayşe Hanım, uzmanların tavsiyelerini dinleyerek ve ücretsiz eğitim materyallerini kullanarak
                                        alım-satım işlemlerini hızla öğrendi. Her geçen gün kendini geliştirdi, stratejilerini güçlendirdi
                                        ve piyasa dinamiklerini daha iyi anlamaya başladı.</p>
                                    <blockquote className="uk-margin-large-top">
                                        <p className="uk-text-large uk-text-italic uk-margin-remove">
                                            “Başlangıçta zorlandım; çünkü bu benim için tamamen yeni bir alandı. Ama vazgeçmedim. Eğitim
                                            materyallerini dikkatlice inceledim, uzmanların önerilerine kulak verdim. Zamanla kendime olan
                                            güvenim arttı ve kazançlarım katlanarak büyüdü.”
                                        </p>
                                        <footer className="uk-margin-small-top uk-text-right color-white">— Ayşe Hanım</footer>
                                    </blockquote>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <img className="img2" src={Image2} alt="" />
                                </div>
                            </div>
                            <div className="uk-grid uk-grid-medium uk-flex-center uk-flex-middle uk-margin-xlarge-top" uk-grid>
                                <div className="uk-width-1-2@s">
                                    <img className="img3" src={Image3} alt="" />
                                </div>
                                <div className="uk-width-1-2@s hero-image">
                                    <h2>Milyon Dolarlık Kazanca Uzanan Serüven</h2>
                                    <p>Ayşe Hanım’ın azmi ve kararlılığı, onu kısa sürede milyon dolarlık bir kazanca ulaştırdı. Artık
                                        finansal özgürlüğüne kavuşmuş, çocuklarına ve kendine daha iyi bir yaşam sunmanın mutluluğunu
                                        yaşıyor.</p>
                                    <blockquote className="uk-margin-large-top">
                                        <p className="uk-text-large uk-text-italic uk-margin-remove">
                                            “Hayatım tamamen değişti. Çocuklarımın yüzündeki mutluluğu görmek her şeye değer. Artık geleceğe
                                            umutla bakıyorum ve hikâyemle başkalarına da ilham olmak istiyorum.”
                                        </p>
                                        <footer className="uk-margin-small-top uk-text-right color-white">— Ayşe Hanım</footer>
                                    </blockquote>
                                </div>
                            </div>

                            <footer className="uk-margin-xlarge-top uk-margin-xlarge-bottom">
                                <div className="footer-designer">
                                    <div className="logo-wrapper">
                                        <img className="footer-logo" alt="" src={Logo} />
                                    </div>
                                    <div className="image-5-parent">
                                        <img className="image-5-icon" alt="" src={Image5} />
                                        <img className="image-6-icon" alt="" src={Image6} />
                                    </div>
                                </div>
                                <p className='copyright-footer' style={{ color: '#999', fontSize: '15px' }}>Copyright Fon Paylaşım</p>
                            </footer>
                        </div>
                }
            </div>
        </div>
    );
}

export default App;
